.header {
  /* font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  font-family: Roboto, sans-serif !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  font-size: 20px;
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

.logo-wrapper {
  padding: 12px 22px 12px 12px;
  border-radius: 0px;
  border: solid 1px #261d2b;
}

.logo {
  font-family: Roboto, sans-serif !important;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;

}

.logo-text {
  padding-left: 12px;
}

.links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-link {
  padding-left: 20px;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

svg {
  display: inline-block;
  vertical-align: top;
}

h1 {
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  margin: 6px 0 6px 10px;
  display: inline-block;
  vertical-align: top;
}

button + button {
  margin-left: 10px;
}

.welcome {
  color: #333;
  font-size: 14px;
  margin-right: 10px;
}

@media screen and (max-width: 680px) {
  .hide-on-mobile {
    display: none;
  }
}

@media screen and (max-width: 395px) {
  .menu-link {
    font-size: 14px;
  }

  .logo {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .hide-320 {
      display: none;
  }

  .logo-wrapper {
    padding: 6px 18px 6px 8px;
  }
}