.ac-outline {
    border: solid 1px var(--border);
    border-radius: 0px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 44px;
    padding-right: 44px;
    min-height: 220px;
    color: #fff;
    -webkit-transition: 150ms ease-in-out;
    transition: 150ms ease-in-out;
}

.ac-outline:hover .ac-toc-text {
    color: #fff;
}

.ac-heading {
    display: flex;
    flex-direction: row;
    margin-top: -30px;
}

.ac-emoji {
    font-family: Roboto, sans-serif !important;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;

}
.ac-title {
    margin-left: 16px;
    font-family: Roboto, sans-serif !important;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.ac-description {
    font-family: Roboto, sans-serif !important;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-top: 16px;
    margin-bottom: 16px;
}
.ac-toc-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
}
.ac-toc-divider {
    width: 100%;
    height: 1px;
    background-color: var(--border);
}
.ac-toc-text {
    padding-top: 12px;
    padding-bottom: 10px;
    margin-bottom: 0;
    font-family: Roboto, sans-serif !important;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--border);
    align-self: center;
    text-align: center;
    -webkit-transition: 150ms ease-in-out;
    transition: 150ms ease-in-out;
}