:root {
    --background: #000000;
    --status-bar-background: #000000;
    --primary: #E42575;
    --grey: #797F91;
    --border: rgba(87, 87, 87, 0.48);
    font-family: Roboto, sans-serif !important;
}

.body {
    background-color: var(--status-bar-background);
    height: 100%;
    padding: 0 42px 0 42px;
}

@media screen and (max-width: 500px) {
    .body {
        padding: 0 28px;
    }
}

.glow-on-hover {
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 0px;
}
.glow-on-hover.px18 {
    border-radius: 0px;
}
.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, var(--background), #ff00c8, #ff0000, #EB6841, var(--primary));
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    -webkit-animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    -webkit-transition: opacity .3s ease-in-out;
    border-radius: 0px;
}
.glow-on-hover.colors-only:before {
    content: '';
    background: linear-gradient(45deg, #ff00c8, #ff0000, var(--primary), #E34A27);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 15s linear infinite;
    -webkit-animation: glowing 15s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    -webkit-transition: opacity .3s ease-in-out;
    border-radius: 0px;

}
.glow-on-hover.px18:before {
    border-radius: 0px;
}
.glow-on-hover:active {
    color: #fff
}
.glow-on-hover:hover:before {
    opacity: 1;
}
.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    left: 0;
    top: 0;
    border-radius: 0px;
}

.glow-on-hover.px18:after {
    border-radius: 0px;
}
.glow-on-hover.no-bg:after {
    background: var(--status-bar-background);
}
.glow-on-hover.pink-bg:after {
    background: var(--primary);
}
.glow-on-hover.bg:after {
    background: var(--background);
}
@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.rise-on-hover-150 {
    -webkit-transition: 150ms ease-in-out;
    transition: 150ms ease-in-out;
}
.rise-on-hover-300 {
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
}
.rise-on-hover-150:hover {
    -webkit-transform: translate(0,-2px);
    -ms-transform: translate(0,-2px);
    transform: translate(0,-2px);
}
.rise-on-hover-300:hover {
    -webkit-transform: translate(0,-2px);
    -ms-transform: translate(0,-2px);
    transform: translate(0,-2px);
}

.center-children-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

