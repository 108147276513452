.mb-wrapper {
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    font-family: Roboto, sans-serif !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: normal;
    align-items: center;
}

.mb-heading {
    font-size: 56px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 8px;
}

.mb-subtitle {
    font-family: Roboto, sans-serif !important;
    font-size: 28px;
    color: #e42575;
    margin-bottom: 52px;
}

.mb-desc {
    font-size: 22px;
    color: var(--grey);
    max-width: 500px;
    font-weight: 400;
}

.mb-desc-styled {
    color: #e42575;
}

.solanaLogo {
    width: 121px;
    height: 18px;
    object-fit: contain;
}

.mb-building-on-text {
    font-family: Roboto, sans-serif !important;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--grey);
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    margin-top: 80px;
}

.mb-button {
    margin-top: 42px;
    padding: 16px 32px;
    border-radius: 0;
    background-color: var(--primary);
    cursor: pointer;
    color: white;
    text-decoration: none;
    height: 28px;
}

.mb-button-text {
    font-family: Roboto, sans-serif !important;
    font-size: 20px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}