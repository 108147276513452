.divider {
    width: 100%;
    height: 1px;
    background-color: var(--border);
    margin-top: 64px;
}

.heading {
    font-family: Roboto, sans-serif !important;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}